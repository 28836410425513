<template>
  <div class="w-sm-100 w-xs-100 px-5">
    <div class="row m-3">
      <div class="row">
        <h4 class="mb-2"><strong>Change password</strong></h4>
      </div>
      <div class="row">
        <div class="row">
          <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
            <label for="current-password" class="control-label"> Current Password</label>
            <div class="input-group">
              <input type="password" id="current-password"
                     name="current-password"
                     class="form-control" v-model="currentPassword">
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
            <label for="new-password" class="control-label"> New Password</label>
            <div class="input-group">
              <input type="password" id="new-password"
                     name="new-password"
                     class="form-control" v-model="newPassword">
            </div>
            <small><span style="font-size: 0.750rem !important;"
                         :class="{'text-error' : validationNewPasswordError}">Password should be at least 6 characters,
                                contain at least one numeric digit, one uppercase and one lowercase letter.</span></small>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
            <label for="retype-new-password" class="control-label"> Confirm New Password</label>
            <div class="input-group">
              <input type="password" id="retype-new-password"
                     name="retype-new-password"
                     class="form-control"
                     v-model="confirmNewPassword"
                     :disabled="!(newPassword.length > 0)"
              >
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
            <p v-if="changePasswordError" class="text-error">Unable to change password. Please try
              again.</p>
            <p v-else-if="validationPasswordSimilarError" class="text-error">Your new password must not be the same as your old password.</p>
            <p v-else-if="changePasswordSuccessful" class="text-success">Password has been changed
              successfully !</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-xs-12 col-sm-12 col-lg-6 mb-3">
            <div v-if="showChangeButton">
              <button class="btn btn-primary mr-2" @click="saveNewPassword()"
                      :disabled="isConfirmPasswordSame">
                Change
              </button>
            </div>
            <div v-else>
              <button class="btn btn-primary" v-if="changePasswordSuccessful" @click="goToLogoutPage()">
                Logout & Login again
              </button>
              <button class="btn btn-warning" v-if="changePasswordError" @click="tryAgain()">
                Try again
              </button>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import BigNumber from 'bignumber.js';
import {SearchIcon, ChevronDownIcon} from 'vue-feather-icons'
// import { countries } from 'country-flag-icons';
// import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import CountryFlag from 'vue-country-flag'

import {debounce} from '../../helpers/helpers'

// Vuesax Component Framework
// import Vuesax from 'vuesax'
// import 'vuesax/dist/vuesax.css'; // Vuesax
// Vue.use(Vuesax);


export default {
  name: 'trading-settings-page',
  components: {

  },
  data() {
    return {
      mode: process.env.VUE_APP_STAGING_ENV ? null : "production",

      userEmail: "",

      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",

      changePasswordSuccessful: false,
      changePasswordError: false,

      currentPasswordError: false,

      showChangeButton: true,

      validationNewPasswordError: false,
      validationPasswordSimilarError : false,

      debugConsole : false,


    }
  },
  watch: {
    $route() {

    },
  },
  computed: {
    ...mapGetters(["userDetailsGetter"]),


    isConfirmPasswordSame() {
      if (!this.newPassword || this.newPassword !== this.confirmNewPassword) {
        return true;
      } else {
        return false;
      }
    }
  },
  metaInfo: {
    title: 'TransCrypt - Transfer Form | Send Money Faster with Cryptocurrency',
  },
  mounted() {
    this.userEmail = this.userDetailsGetter && this.userDetailsGetter.email;

  },
  beforeDestroy() {

  },
  methods: {
    ...mapActions(["changePassword"]),
    saveNewPassword() {

      if (!(this.checkPassword(this.newPassword))) {
        this.validationNewPasswordError = true;
      } else {
        this.validationNewPasswordError = false;
      }

      if (this.currentPassword === this.newPassword) {
        this.validationPasswordSimilarError = true;
      } else {
        this.validationPasswordSimilarError = false;
      }


      if (this.validationNewPasswordError || this.validationPasswordSimilarError) {
        return;
      }

      let data = {};
      data.old_password = this.currentPassword;
      data.new_password = this.newPassword;

      this.changePassword(data).then(res => {
        if(this.debugConsole) console.log("change password result in settings", res);

        if (res.data && res.data.status === "OK") {
          this.showChangeButton = false;
          this.changePasswordSuccessful = true;
          this.changePasswordError = false;
          this.validationNewPasswordError = false;


          this.currentPassword = "";
          this.newPassword = "";
          this.confirmNewPassword = "";
        } else {
          this.changePasswordError = true;
        }
      }).catch(err => {
        if(this.debugConsole) console.log("Change password error in settings", err.response);

        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors.find(item => item.message === "invalid" && item.path === "password")) {
          this.validationNewPasswordError = true;
          this.changePasswordError = true;
          this.showChangeButton = false;
        } else if (err.response.data.message === 'validation_error') {
          this.showChangeButton = false;
          this.changePasswordError = true;
        }
      })
    },
    tryAgain() {
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";

      this.showChangeButton = true;
      this.changePasswordSuccessful = false;
      this.changePasswordError = false;

      this.currentPasswordError = false;
      this.validationNewPasswordError = false;
    },
    goToLogoutPage() {
      this.$router.push('/trading/user/logout');
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";

      this.showChangeButton = true;

      this.changePasswordSuccessful = false;
      this.changePasswordError = false;

      this.currentPasswordError = false;
      this.validationNewPasswordError = false;
    }
  }
}

</script>
<style>


</style>